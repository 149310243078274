import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
import KeyFeatures from "/src/components/pages/products/KeyFeatures";
import Benefits from "/src/components/pages/products/Benefits";
import Functions from "/src/components/pages/products/Functions";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit02.json";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";

const ZorpMalware = ({data}) => {

    const {malware} = data

    return (
        <Layout lang={malware.lang} seo={malware.SEO}>
            <Header
                title_white={malware.product.title_white}
                title_color={malware.product.title_color}
                subtitle={malware.product.subtitle}
                brand_color="zorp-malware-primary"
                description={malware.product.description}
                url={malware.product.url}
                url_download={malware.product.url_download}
                src={malware.product.product_icon.url}
            />
            <KeyFeatures
                big_title={malware.key_features_title}
                cards={malware.Key_features_card}
            />
            <LottieAnimation animationData={circuit2} turned="true"/>
            <Benefits
                big_title={malware.benefits_title}
                benefits={malware.benefits_card}
                button={malware.benefits_button}
                button_style="zorp-malware-primary"
            />
            <Functions
                big_title={malware.function_title}
                cards={malware.function_card}
                button={malware.function_button}
                isMultipleButton
                button_color="zorp-malware-primary"
            />
            <RelatedContentSection
                locale={malware.lang}
                related1_slug={malware.related_content.related1_slug}
                related2_slug={malware.related_content.related2_slug}
                related3_slug={malware.related_content.related3_slug}
                related4_slug={malware.related_content.related4_slug}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleMalware($locale: String) {
         malware: strapiZorpMalwares(lang: { eq: $locale }) {
          lang
          product:Header {
            title_white
            title_color
            subtitle
            description
            product_icon {
              url
            }
            url {
              link
              name
            }
            url_download {
              file {
                url
              }
              name
            }
          }
          key_features_title
          Key_features_card {
            id
            title
            list {
              id
              item
            }
            avatar {
              url
            }
          }
          benefits_title
          benefits_card {
            id
            title
            description
          }
          benefits_button {
            link
            name
            id
          }
          function_title
          function_card {
            title
            id
            avatar {
              url
            }
            List {
              id
              item
            }
          }
          function_button {
            id
            link
            name
          }
          related_content {
                related1_slug
                related2_slug
                related3_slug
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default ZorpMalware